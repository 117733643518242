:root {
    --Color_Brand: #333;
    --Color_LightPink: #fdf5f5;
    --Color_CoolCream: #fbf0eb;
    --Color_Coffee: #dfc9b6;
    --Color_WarmPink: #e2a9a9;
    --Color_Russet: #d49595;
    --Color_CoolPink: #fae0df;
    --Color_CoolPink-r: 250;
    --Color_CoolPink-g: 224;
    --Color_CoolPink-b: 223;
    --Color_CoolPink: rgb( var(--Color_CoolPink-r), var(--Color_CoolPink-g), var(--Color_CoolPink-b) );
    --Color_CoolPink-70: rgba( var(--Color_CoolPink-r), var(--Color_CoolPink-g), var(--Color_CoolPink-b), 0.7 );
    --Color_Black-r: 0;
    --Color_Black-g: 0;
    --Color_Black-b: 0;
    --Color_Black: rgb( var(--Color_Black-r), var(--Color_Black-g), var(--Color_Black-b) );
    --Color_Black-10: rgba( var(--Color_Black-r), var(--Color_Black-g), var(--Color_Black-b), 0.1 );
    --Color_Black-50: rgba( var(--Color_Black-r), var(--Color_Black-g), var(--Color_Black-b), 0.5 );
    --Color_Black-70: rgba( var(--Color_Black-r), var(--Color_Black-g), var(--Color_Black-b), 0.7 );
    --Color_Grey-r: 51;
    --Color_Grey-g: 51;
    --Color_Grey-b: 51;
    --Color_Grey: rgb( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b) );
    --Color_Grey-10: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.1 );
    --Color_Grey-15: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.15 );
    --Color_Grey-20: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.2 );
    --Color_Grey-30: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.3 );
    --Color_Grey-50: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.5 );
    --Color_Grey-90: rgba( var(--Color_Grey-r), var(--Color_Grey-g), var(--Color_Grey-b), 0.9 );
    --Color_Grey-overlay: var(--Color_Grey-20);
    --Color_Grey-lightest: #f4f4f4;
    --Color_Grey-lighter: #e0e0e0;
    --Color_Grey-light: #bdbdbd;
    --Color_Grey-medium: #828282;
    --Color_White-r: 255;
    --Color_White-g: 255;
    --Color_White-b: 255;
    --Color_White: rgb( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b) );
    --Color_White-light: rgba( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b), 0.5 );
    --Color_White-overlay: rgba( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b), 0.2 );
    --Color_White-10: rgba( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b), 0.1 );
    --Color_White-20: rgba( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b), 0.2 );
    --Color_White-70: rgba( var(--Color_White-r), var(--Color_White-g), var(--Color_White-b), 0.7 );
    --Color_Red-r: 235;
    --Color_Red-g: 87;
    --Color_Red-b: 87;
    --Color_Red: rgb(var(--Color_Red-r), var(--Color_Red-g), var(--Color_Red-b));
    --Color_Red-70: rgb( var(--Color_Red-r), var(--Color_Red-g), var(--Color_Red-b), 0.7 );
    --Color_Green: #12d60e;
    --Color_Yellow: #ffc700;
    --Color_Anchor: #373737;
    --Color_Main: black;
    --Color_Nav: var(--Color_CoolPink);
    --Color_Body: var(--Color_Main);
    --Color_Border: var(--Color_Grey-lighter);
    --Color_Error: var(--Color_Red);
    --Color_Success: var(--Color_Green);
    --Color_Main-DarkMode: #fff;
    --Color_Body-DarkMode: #fff;
    --Color_Black-DarkMode: #fff;
    --Color_Nav-DarkMode: var(--Color_Black-DarkMode);
    --Font_Family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    --Font_Family-body: 'Work Sans', var(--Font_Family);
    --Font_Family-headings: 'europa', var(--Font_Family);
    --Grid_Gutter: 5px;
    --Nav_Height: 60px;
    --Nav_HeightWhenFixed: var(--Nav_Height);
    --Announcement_Height: 0px;
    --Usp_Height: 0px;
    font: 14px / 1.5 var(--Font_Family-body);
    cursor: auto;
}