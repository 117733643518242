body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search-container {
  font-family: "ProximaNova-Semibold", -apple-system, BlinkMacSystemFont,
  "San Francisco", "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, Arial,
  sans-serif;
}

.search-page_product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.search-page_title {
  font-size: 16px;
}
.search-page-product-card {
  display: flex;
  flex-direction: column;
}
.search-page-product-card .product-image {
  height: 360px !important;
  width: 100% !important;
  object-fit: cover;
}
.search-page-product-card .quick-add-btn {
  background-color: black;
  color: white;
  text-align: center;
  display: block;
  padding: 0.25rem;
}
.search-page-product-card .search-page-card-details {
  position: relative;
  padding-right: 30px;
}
.search-page-product-card .search-page-card-details .search-page-product-price {
  margin-bottom: 0.125rem;
  margin-top: 0.5rem;
}
.search-page-product-card .search-page-card-details .search-page-product-title {
  margin-bottom: 0.25rem;
  margin-top: 0.125rem;
  font-size: 14px;
  color: gray;
}
.search-page-product-card .search-page-card-details .heart-btn {
  position: absolute;
  right: 0px;
  top: 20px;
}
.search-page-product-card .search-page-card-details .heart-btn svg {
  width: 25px;
  height: 25px;
}
.back-btn {
  display: flex;
  border: none;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.back-btn svg {
  width: 40px;
  height: 40px;
  color: black;
}

.search-container {
  font-size: 12px;
  padding-top: 80px;
}
.site-header__search-input-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  max-width: 500px;
}
/*.site-header__search-input-wrapper::before {*/
/*  content: "";*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  display: black;*/
/*  position: absolute;*/
/*  left: 5px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'%3E%3C/path%3E%3C/svg%3E");*/
/*}*/
.site-header__search-input {
  width: 100%;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border-color: #dfdbdb;
  padding: 10px 8px ;
  border-radius: 4px;
  margin-top: 24px;
  border: 0;
  outline: none;
}
.product-card {
  width: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  border-radius: 10px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 20px;
}

.products-header {
  text-align: left;
  margin-bottom: 10px;
}

.card-details {
  margin-left: 4px;
  text-align: left;
}

.card-details p {
  margin-top: 0;
}
.search-tags {
  display: flex;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.search-tags::-webkit-scrollbar {
  display: none;
}
.search-tags button, .search-tags a {
  color: black;
  text-decoration: none;
  padding: 8px 16px;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 50px;
  margin-right: 8px;
  flex-shrink: 0;
  background-color: transparent;
  cursor: pointer;
}
.product-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.popular-collections h1{
  display: block;
  margin-block-end: 20px;
  font-family: 'europa', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: #333;
}
.popular-collections {
  padding: 0px 15px;
}

.popular-collections .popular-collections-container {
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popular-collections-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.popular-collections-container {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}


.popular-collections .popular-collections-container .popular-collection .popular-collection-img img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.4s ease;
}

.popular-collections .popular-collections-container .popular-collection .popular-collection-img {
  position: relative;
  padding-top: 216px;
  padding-left: 150px;
}

.popular-collections .popular-collections-container .popular-collection {

  /* width: 150px; */
  height: 268px;
}

.popular-collection-button p {
  margin: 0px;
}

.popular-collection-button {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 56px;
  padding: 10px 15px;
  font-family: 'europa', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 18.2px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fae0df;
  color: var(--Color_Brand);
  transition: background-color 0.4s ease, color 0.4s ease;
}

.popular-searches {
  display: block;
  margin-block-end: 20px;
  font-family: 'europa', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: #333;
}